<template>
  <div class="ma-config reset-btn-page">
    <div class="content">
      <div v-loading="show_loading">
        <el-form
          :model="form"
          :rules="rules"
          ref="ma-form"
          label-width="100px"
          label-position="right"
          size="medium"
          @submit.native.prevent
        >
          <el-form-item label="接收对象：" prop="receive_type">
            <el-select
              style="width: 350px"
              size="medium"
              v-model="form.receive_type"
              placeholder="请选择接收对象"
            >
              <el-option
                v-for="(item, index) in receiveList"
                :label="item.name"
                :value="item.type"
                :key="index"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            v-show="form.receive_type == 0"
            label="组织架构："
            prop="receivers"
          >
            <el-cascader
              style="width: 350px"
              v-model="form.receivers"
              :options="organizationList"
              :props="{
                emitPath: false,
                label: 'name',
                value: 'id',
                children: 'children',
                multiple: true,
              }"
            >
            </el-cascader>
          </el-form-item>
          <el-form-item label="标题：" prop="title">
            <el-input
              size="medium"
              style="width: 350px"
              v-model="form.title"
              placeholder="请输入标题"
              :validate-event="false"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="页面内容：" prop="content">
            <!-- <el-input v-model="form.textarea" type="textarea" :rows="28" placeholder="请输入内容"></el-input>-->
            <tinymce
              id="tinymce-single-page-editor"
              @choose="handleToolBarClick"
              v-model="form.content"
              :height="550"
            >
            </tinymce>
          </el-form-item>
          <el-form-item label="轮播时间：" prop="start_time">
            <DatePeriodPicker
              :showBtn="false"
              start-prop="create_start_time"
              :start-time.sync="form.start_time"
              end-prop="create_end_time"
              :end-time.sync="form.end_time"
            />
          </el-form-item>
          <el-form-item label="置顶：" prop="is_top">
            <el-radio-group v-model="form.is_top">
              <el-radio :label="1">开启</el-radio>
              <el-radio :label="0">关闭</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
    </div>

    <media-selector
      v-model="showImageList"
      multiple
      @select="updateImages"
    ></media-selector>
    <media-selector
      v-model="showVideoList"
      type="video"
      multiple
      @select="updateVideos"
    ></media-selector>
    <media-selector
      v-model="showAudioList"
      type="audio"
      multiple
      @select="updateAudios"
    ></media-selector>
    <!-- 文章导入 -->
    <import-article
      v-model="articleImport.show"
      @confirm="getImportDetail"
    ></import-article>
    <import-html-code v-model="isShowHtmlInput"></import-html-code>
    <fixed-action-bar :z-index="2">
      <el-button @click="onCancel">取消</el-button>
      <el-button type="primary" :loading="saveLoading" @click="onSave"
        >{{ this.form.id === 0 ? "发送" : "编辑" }}
      </el-button>
    </fixed-action-bar>
  </div>
</template>
<script>
import {
  getOrganizationsTree,
  editBroadcastData,
  getBroadcastDetail,
} from "../api/cyc_broadcast_message";

import FixedActionBar from "@/base/layout/FixedActionBar";
import MediaSelector from "../../common/components/MediaSelector";
import SingleMediaWall from "@/modules/common/components/SingleMediaWall.vue";

import Tinymce from "@/base/components/Editor/Tinymce";
import ImportArticle from "@/base/components/Editor/ImportArticle";
import ImportHtmlCode from "@/base/components/Editor/ImportHtmlCode";
import DatePeriodPicker from "@/base/components/Form/DatePeriodPicker";

export default {
  data() {
    let validateText = (rule, value, callback) => {
      if (this.form.textarea === "") {
        callback(new Error("请输入广播内容"));
      } else {
        callback();
      }
    };
    let validateTime = (rule, value, callback) => {
      if (this.form.start_time === -1 || this.form.end_time === -1) {
        callback(new Error("请选择轮播时间"));
      } else {
        callback();
      }
    };
    return {
      form: {
        content: "",
        title: "",
        id: "", // id 为0 是新增
        is_top: 0,
        // 接收对象数组id
        receivers: [],
        start_time: -1,
        end_time: -1,
        // 接收对象类型
        receive_type: 3,
        create_start_time: "123123",
      },
      // 接收对象数组
      organizationList: [],
      // 接收对象列表
      receiveList: [
        { name: "组织", type: 0 },
        { name: "全部会员", type: 1 },
        { name: "非会员", type: 2 },
        { name: "全部(包括非会员)", type: 3 },
      ],
      rules: {
        receive_type: [{ required: true, message: "请选择接收对象" }],
        title: [{ required: true, message: "请输入标题" }],
        fixedTime: [{ required: true, message: "请选择发布时间" }],
        start_time: [{ required: true, message: "请选择发布时间" }],
        content: [
          { validator: validateText },
          { required: true, message: "请输入广播内容" },
        ],
        start_time: [{ validator: validateTime }, { required: true }],
      },

      saveLoading: false, //保存中
      showImageList: false,
      showVideoList: false,
      showAudioList: false,
      show_loading: false,
      articleImport: {
        show: false,
      },
      isShowHtmlInput: false,
    };
  },
  computed: {},
  created() {
    this.form.id = this.$route.params.id;

    // 请求架构权限
    this.getParentToList();
    // 请求详情
    this.getCycDetail();
  },
  methods: {
    // 获取所属组织列表
    getParentToList() {
      getOrganizationsTree({ is_show_none: 0, is_all: 1 }).then((res) => {
        // 赋值架构数组
        this.organizationList = res.data;
      });
    },
    getImportDetail(e) {
      // window.tinymce
      //   .get("tinymce-single-page-editor")
      //   .execCommand("mceReplaceContent", false, e.content + "<br/>");
      this.form.content = e.content + "<br />";
    },
    // 获取消息详情
    getCycDetail() {
      if (this.form.id != 0) {
        this.show_loading = true;
        getBroadcastDetail({ id: this.form.id })
          .then((res) => {
            this.form = res.data;
            this.show_loading = false;
          })
          .catch((err) => {
            this.show_loading = false;
          });
      } else {
        this.show_loading = false;
      }
    },
    updateImages(e) {
      e.forEach((item) => {
        window.tinymce.execCommand(
          "mceReplaceContent",
          false,
          `<img src="${item.url}"/>`
        );
      });
      this.showImageList = false;
    },
    updateVideos(e) {
      e.forEach((item) => {
        window.tinymce.execCommand(
          "mceReplaceContent",
          false,
          `<p><span class="mce-preview-object mce-object-video"
                contenteditable="false"
                data-mce-object="video"
                data-mce-p-controls="controls"
                data-mce-p-controlslist="nodownload"
                data-mce-p-allowfullscreen="true"
                data-mce-p-frameborder="no"
                data-mce-p-scrolling="no"
                data-mce-html=""
                data-mce-p-src="${item.url}">
                <video controls src="${item.url}"></video></span></p>
                <p><br/></p>`
        );
      });
      this.showVideoList = false;
    },
    updateAudios(e) {
      e.forEach((item) => {
        window.tinymce.execCommand(
          "mceReplaceContent",
          false,
          `<p><span class="mce-object-audio"
            contenteditable="false"
            data-mce-object="audio"
            data-mce-p-controls="controls"
            data-mce-p-controlslist="nodownload"
            data-mce-p-frameborder="no"
            data-mce-p-scrolling="no"
            data-mce-html=""
            data-mce-p-src="${item.url}">
            <audio controls src="${item.url}"></audio></span></p>
            <p><br/></p>`
        );
      });
      this.showAudioList = false;
    },
    //选择图片
    chooseImages() {
      this.showImageList = true;
    },
    //选择视频
    chooseVideos() {
      this.showVideoList = true;
    },
    //选择音频
    chooseAudios() {
      this.showAudioList = true;
    },
    // 富文本框点击图片，视频，音频返回事件
    handleToolBarClick(e) {
      switch (e.type) {
        case "image":
          this.chooseImages();
          break;
        case "video":
          this.chooseVideos();
          break;
        case "audio":
          this.chooseAudios();
          break;
        case "import":
          this.articleImport.show = true;
          break;
        case "html":
          this.isShowHtmlInput = true;
          break;
      }
    },
    //保存
    onSave() {
      this.$refs["ma-form"].validate((valid) => {
        if (valid) {
          this.saveLoading = true;
          // 是否选择了开始和结束时间
          if (this.form.start_time === -1 || this.form.end_time === -1) {
            this.$message.error("请填写完整轮播时间！");
            return false;
          }
          // 如果开始时间大于结束时间
          if (this.form.start_time > this.form.end_time) {
            this.$message.error("结束日期不可小于开始日期");
            return false;
          }
          const postData = this.form;
          if (postData.receive_type !== 0) {
            // 如果接收对象不是组织，返回空的接收对象ID数组
            postData.receivers = [];
          }
          console.log(postData);

          editBroadcastData(postData)
            .then((res) => {
              console.log(res);
              this.$message({
                message: "操作成功！",
                type: "success",
              });
              this.saveLoading = false;
              this.$router.go(-1);
            })
            .catch((err) => {
              this.saveLoading = false;
            });
        } else {
          setTimeout(() => {
            var isError = document.getElementsByClassName("is-error");
            isError[0].scrollIntoView({ block: "center", behavior: "smooth" });
          }, 100);
          this.$message.error("请填写完整信息！");
          return false;
        }
      });
    },

    //取消
    onCancel() {
      this.$router.go(-1);
    },
  },
  components: {
    ImportHtmlCode,
    ImportArticle,
    MediaSelector,
    FixedActionBar,
    Tinymce,
    SingleMediaWall,
    DatePeriodPicker,
  },
};
</script>
<style lang="scss" scoped>
.ma-config {
  margin: -$space;
  margin: 12px;

  .head {
    margin-bottom: 20px;

    .title {
      font-size: 18px;
      line-height: 25px;
      font-weight: 500;
      color: rgba(0, 0, 0, 1);
    }
  }

  .content {
    // display: flex;
    width: 100%;

    .el-input {
      width: 350px;
    }
  }
}
</style>
